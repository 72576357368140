import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './common/css/base.scss'
import './assets/icon/iconfont.css'
import { msg } from './components/message'

Vue.use(ElementUI)
Vue.mixin(msg)
Vue.config.productionTip = false

new Vue({
	render: (h) => h(App)
}).$mount('#app')
