//blocknative 库配置
let PkeyProd= ''
//c测试
switch (process.env.NODE_ENV) {
	// 测试环境
	case 'test':
		PkeyProd = '12e045d50d5ec165dbdcfc5e9f386a4df585fb8aecdea2290119b402630aebf96e7adb64d7ad7469a74626511b8cfb9102550455e36c111ddce7c245738915fe'
		break

	// 生产环境
	case 'production':
		PkeyProd = '43c12ec2c8cf80e831fbef8d1c17c0da47778ff4f7c28ed6af249e4f69bb13179eaf7a777d68615a49444ad46cf44e140fe0a097f7d89293892134cd4ca149d4'
		break
}
export {
	PkeyProd,
}
