const downloadJson = function (name, json) {
	// encodeURIComponent解决中文乱码
	const uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(json)
	const a = document.createElement('a')
	const event = new MouseEvent('click')
	a.download = name
	a.href = uri
	a.dispatchEvent(event)
}

export { downloadJson }
