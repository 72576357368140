<template>
  <div class="body">
    <header>
      <img :src="require('../assets/yeez.png')" alt="" />
    </header>
    <div class="one" v-if="isOne">
      <h1>欢迎使用MetaEncryptor</h1>
      <h3>
        MetaEncryptor是一个数据加密及元数据生成工具，用户可以使用指定密钥对原始数据进行非对称加密，同时生成相应的的元数据描述文件。
        <br />
        生成的加密文件及元数据描述文件通常用于典枢平台中的发布数据流程。
      </h3>
      <el-button type="primary" @click="setTwo">开始使用</el-button>
    </div>
    <div class="two" v-if="isTwo">
      <p>凭证文件是您管理数据的依据，请您务必妥善保管</p>

      <div class="uploudkey">
        <div class="sheng" @click="setObj('sheng')">
          <span class="iconfont icon-wj-fswj"></span>
          <span class="icontext"> 点击生成凭证文件</span>
        </div>
        <el-upload
          class="chuan"
          action="#"
          :auto-upload="false"
          :limit="1000"
          :on-change="handleBeforeUpload"
          :show-file-list="false"
          multiple
        >
          <span class="iconfont icon-wj-wjsc"></span>
          <span class="icontext">点击上传凭证文件 </span>
        </el-upload>
        <!-- <span>上传已有凭证文件</span> -->
      </div>
      <span class="fileName">{{ fileName }}</span>
      <el-button type="primary" @click="setThr">下一步</el-button>
    </div>

    <div class="thr" v-if="isThr">
      <el-upload
        class="chuan"
        action="#"
        :limit="1000"
        :on-change="onChange"
        :show-file-list="false"
        :auto-upload="false"
        :disabled="percentage === 100"
        multiple
      >
        <span class="iconfont icon-wendangjiami"></span>

        <span class="icontext">{{ thrName }}</span>
        <span class="thrFileName" v-show="thrFileName"> {{ thrFileName }}</span>
      </el-upload>
      <div class="progress" v-show="percentage > 1">
        <el-progress
          :text-inside="true"
          :stroke-width="26"
          :percentage="percentage"
          color="#34e8e8"
        ></el-progress>
      </div>
      <el-button type="primary" @click="setFour">下一步</el-button>
    </div>

    <div class="for" v-if="isFour">
      <div class="thrname" v-if="!isWan">
        <span class="iconfont icon-wj-wjxf"></span>
        <p>文件加密成功</p>
        <span>{{ sealedName }}</span>
        <span>{{ metaName }}</span>
      </div>
      <div class="thrname" v-else>
        <span class="iconfont icon-wancheng"></span>
        <p>请将生成的 .csv.meta.json 格式文件上传到平台，完成数据发布</p>
      </div>
      <el-button type="primary" @click="downObj" v-if="!isWan"
        >下载文件
      </el-button>
      <el-button type="primary" @click="complete" v-else>完成</el-button>
    </div>

    <div class="errordiv" v-if="errMsg">
      <div class="thrname">
        <span class="iconfont icon-cuowu"></span>
        <div>{{ errMsg }}</div>
      </div>
      <el-button type="primary" @click="retry">再试一次</el-button>
    </div>
  </div>
</template>

<script>
import Data_Provider from '@/utils/dataprovider';
import YPC_Crypto from '@/utils/ypccrypto';
const DataProvider = Data_Provider();
const YPCCrypto = YPC_Crypto();

import { PkeyProd } from '../config.js';
import { downloadJson } from '@/utils';
export default {
  data() {
    return {
      sudu: 1000000,
      isOne: true,
      isTwo: false,
      isThr: false,
      isFour: false,
      dataKey: {},
      fileName: '',
      sealed: null,
      meta: null,
      sealedName: '',
      metaName: '',
      percentage: 0,
      thrName: '点击选择您要加密的原始数据',
      errMsg: '',
      textName: '',
      thrFileName: '',
      isWan: false,
    };
  },
  mounted() {},
  methods: {
    setObj(val) {
      const skey = YPCCrypto.generatePrivateKey();
      const pkey = YPCCrypto.generatePublicKeyFromPrivateKey(skey);
      this.dataKey = {
        private_key: skey.toString('hex'),
        public_key: pkey.toString('hex'),
      };
      const ypcName = YPCCrypto.generateFileNameFromPKey(pkey);
      const ypcJson = YPCCrypto.generateFileContentFromSKey(skey);
      // 下载文件
      downloadJson(ypcName, ypcJson);
      this.fileName = ypcName;
    },
    handleBeforeUpload(file) {
      console.log(file, 'file');
      if (file.raw.type !== 'application/json') {
        this.Error(`请上传 .json 格式文件`, 100);
        return;
      }
      this.dataKey = {};
      const fileReader = new FileReader();
      fileReader.readAsText(file.raw);
      fileReader.onload = (ev) => {
        const data = ev.target.result;
        const obj = JSON.parse(data);
        if (obj.private_key) {
          this.dataKey = obj;
          this.fileName = file.raw.name;
        } else {
          this.dataKey = {};
          this.isBtn = false;
          this.Error('上传的文件不正确。请选择正确的文件！', 100);
        }
      };
    },
    setFour() {
      if (this.percentage === 0)
        return this.Warning('选择加密的数据，等待加密完成后再继续！');
      if (this.percentage !== 100)
        return this.Warning('请等待加密完成后在进行下一步！');
      this.isThr = false;
      this.isFour = true;
    },
    retry() {
      this.errMsg = '';
      this.thrFileName = '';
      this.isThr = true;
    },
    setThr() {
      if (!this.fileName) return this.Warning('请先上传或生成凭证文件！', 100);
      this.isTwo = false;
      this.isThr = true;
    },
    setTwo() {
      this.isOne = false;
      this.isTwo = true;
    },
    onChange(file) {
      const type = ['application/vnd.ms-excel', 'text/csv'];
      if (type.indexOf(file.raw.type) < 0) {
        this.Error(`请上传 .csv 格式文件`, 100);
        return;
      } else {
        this.thrFileName = file.raw.name;
        this.getSealPercent(file.raw.size);
        this.sealFile(file);
      }
    },
    sealFile(file) {
      // check file encoding
      const frb = new FileReader();
      frb.readAsArrayBuffer(file.raw);
      frb.onload = (ev) => {
        const data = ev.target.result;
        const utf8EncodingText = DataProvider.convert2Utf8(data);
        console.log(PkeyProd, 'PkeyProdPkeyProd');

        // init file input stream
        DataProvider.init(file.raw.name, PkeyProd, utf8EncodingText);

        // // check invalid csv lines
        const idx = DataProvider.invalidLineIndex();
        if (idx.length !== 0) {
          clearInterval(this.timer);
          this.timer = null;
          this.percentage = 0;
          this.isThr = false;
          this.errMsg = `第 ${idx.toString()} 行逗号（,）与第1行逗号（,）的个数不同`;
          // return this.Warning(
          // 	`第${idx.toString()}行逗号的个数与第一行逗号的个数不同，请返回修改.CSV文件后再重新提交`
          // )
        } else {
          setTimeout(() => {
            try {
              console.log(this.dataKey, 'this.dataKeythis.dataKeythis.dataKey');
              const res = DataProvider.sealFile(this.dataKey);
              const is_success = DataProvider.checkSealedData(
                this.dataKey,
                res[0],
                res[1]
              );
              if (!is_success) {
                throw 'Check sealed data hash failed!';
              }
              this.sealed = res[0];
              this.meta = res[1];
              this.sealedName = file.raw.name + '.sealed';
              this.metaName = file.raw.name + '.meta.json';
              this.percentage = 100;
              this.thrName = '加密完成，请点击下一步';
            } catch (error) {
              this.percentage = 0;
              clearInterval(this.timer);
              this.timer = null;
              this.isThr = false;
              this.errMsg = '文件加密失败，请重试！';
              // this.Error('文件加密失败，请重试！')
              // console.log(error)
            }
          }, 2000);
        }
      };
    },
    getSealPercent(size) {
      if (size < 5000000) {
        this.percentage = 90;
      } else {
        const sudu = Math.floor(Math.random() * 50 + 50);
        this.timer = setInterval(() => {
          if (this.percentage < 100) {
            this.percentage += 1;
          } else {
            clearInterval(this.timer);
            this.timer = null;
          }
        }, sudu);
      }
    },
    downObj() {
      //  download sealed file
      const blob = new Blob([this.sealed.view]);
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = this.sealedName;
      link.href = blobUrl;
      // 触发点击
      document.body.appendChild(link);
      link.click();
      // 移除
      document.body.removeChild(link);

      // download meta
      const blob1 = new Blob([this.meta]);
      const blobUrl1 = window.URL.createObjectURL(blob1);
      const link1 = document.createElement('a');
      link1.download = this.metaName;
      link1.href = blobUrl1;
      // 触发点击
      document.body.appendChild(link1);
      link1.click();
      // 移除
      document.body.removeChild(link1);
      this.textName = this.metaName;
      this.fileName = '';
      this.metaName = '';
      this.thrName = '点击选择您要加密的原始数据';
      this.dataKey = {};
      this.sealed = null;
      this.meta = null;
      this.sealedName = '';
      this.percentage = 0;
      this.isWan = true;
      // this.isFour = false
      // this.isOne = true
    },
    complete() {
      this.textName = '';
      this.isFour = false;
      this.isOne = true;
      this.thrFileName = '';
      this.isWan = false;
      this.sudu = 1000000;
      this.isTwo = false;
      this.isThr = false;
      this.dataKey = {};
      this.fileName = '';
      this.sealed = null;
      this.meta = null;
      this.sealedName = '';
      this.metaName = '';
      this.percentage = 0;
      this.thrName = '点击选择您要加密的原始数据';
      this.textName = '';
      this.errMsg = '';
      this.isWan = false;
    },
  },
};
</script>

<style scoped lang="scss">
.iconfont {
  font-size: 64px !important;
  color: #34e8e8;
}
.icontext {
  font-size: 20px;
  font-weight: 400;
  color: #34e8e8;
}
.thrFileName {
  font-size: 18px;
  font-weight: 400;
  color: #a3a3a3;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 500px;
}
.body {
  height: 100%;
  width: 100%;
  background-color: #f2f2f2;
  min-height: 600px;
  background: url('~@/assets/index.jpg') no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  header {
    background: url('~@/assets/header.png') no-repeat center center;
    background-size: contain;
    height: 80px;

    img {
      margin: 0 auto;
      display: block;
      width: 120px;
      padding-top: 10px;
    }
  }
}
.one,
.two,
.thr,
.for,
.errordiv {
  height: 400px;
  width: 800px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  background: url('~@/assets/content.png') no-repeat center center;
  background-size: contain;
  margin: auto;
  position: relative;
  .el-button {
    padding: 14px 60px;
    position: absolute;
    bottom: 50px;
    background-color: #34e8e8;
    border-color: #34e8e8;
    color: #01172e;
    font-size: 18px;
  }
}
.errordiv {
  .thrname {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    .iconfont {
      color: #f56c6c;
    }
    div {
      font-size: 20px;
      font-weight: 400;
      margin-top: 10px;
      color: #f56c6c;
      max-width: 500px;
      height: 150px;
      text-align: center;
      word-break: break-word;
      overflow-y: auto;
    }
  }
  p {
    font-size: 20px;
    font-weight: 400;
    color: #f56c6c;
  }
  .progress {
    margin-top: 50px;
    width: 60%;
  }
}
.for {
  .thrname {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
    .iconfont {
      color: #34e8e8;
    }
    i {
      font-size: 64px;
      color: #34e8e8;
    }
    span {
      font-size: 18px;
      font-weight: 400;
      color: #a3a3a3;
      margin-top: 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 500px;
      text-align: center;
    }
  }
  p {
    font-size: 20px;
    font-weight: 400;
    color: #34e8e8;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
    text-align: center;
  }
  .progress {
    margin-top: 20px;
    width: 60%;
  }
}
.thr {
  .thrname {
    display: flex;
    flex-direction: column;
    align-items: center;
    i {
      font-size: 64px;
    }
    span {
      font-size: 18px;
      font-weight: 400;
      color: #34e8e8;
      margin-top: 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 500px;
      text-align: center;
    }
  }
  p {
    font-size: 20px;
    font-weight: 400;
    color: #34e8e8;
  }
  .progress {
    margin-top: 20px;
    width: 60%;
  }
  .chuan {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 40px;
    font-size: 26px;
    cursor: pointer;
    font-weight: 500;
    color: #34e8e8;
    margin-top: 60px;
    .iconfont {
      font-size: 64px;
      color: #34e8e8;
    }
    ::v-deep .el-upload:focus {
      border-color: #34e8e8;
      color: #34e8e8;
    }
    ::v-deep .el-upload:hover {
      border-color: #34e8e8;
      color: #34e8e8;
    }
  }
  .chuan:hover {
    color: #34e8e8;
    .iconfont {
      color: #34e8e8;
    }
  }
  ::v-deep .el-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.two {
  .uploudkey {
    display: flex;
    margin-top: 20px;
    .sheng,
    .chuan {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 40px;
      font-size: 26px;
      cursor: pointer;
      font-weight: 500;
      color: #34e8e8;
      ::v-deep .el-upload:focus {
        border-color: #34e8e8;
        color: #34e8e8;
      }
      ::v-deep .el-upload:hover {
        border-color: #34e8e8;
        color: #34e8e8;
      }
    }
    .sheng:hover {
      color: #34e8e8;
      .iconfont {
        color: #34e8e8;
      }
    }
    .chuan:hover {
      color: #34e8e8;
      .iconfont {
        color: #34e8e8;
      }
    }
  }
  .fileName {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 400;
    color: #a3a3a3;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 500px;
    text-align: center;
  }
  p {
    margin-top: 50px;
    font-size: 26px;
    font-weight: 400;
    color: #34e8e8;
  }
  ::v-deep .el-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.one {
  h1 {
    text-align: center;
    margin-top: 60px;
    font-size: 38px;
  }
  h2 {
    text-align: center;
    margin-top: 60px;
  }
  h3 {
    margin-top: 40px;
    text-align: center;
    font-size: 16px;
    width: 86%;
    text-align: left;
    font-weight: 400;
  }
}
h1 {
  margin: 0;
  color: #34e8e8;
}
h2 {
  margin: 0;
  color: #34e8e8;
}
h3 {
  margin: 0;
  color: #34e8e8;
}
// .el-button {
// 	padding: 14px 60px;
// 	position: absolute;
// 	top: 70%;
// 	left: 50%;
// 	transform: translate(-50%, -50%);
// }
.el-button--primary:focus,
.el-button--primary:hover {
  background: #34e8e8;
  border-color: #34e8e8;
  color: #01172e;
}
::v-deep .el-upload-list__item-name {
  color: #34e8e8;
  i {
    color: #34e8e8;
  }
}
</style>
